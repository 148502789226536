<template>
  <v-container>
    <!-- Empieza boton para exportar a excel -->
    <v-btn small class="ma-2" elevation="2" color="primary">
      <json-excel :data="dataArray" :fields="dataFields" :name="fileName">
        {{ excelButtonText }}
      </json-excel>
      <v-icon right> {{ excelIcon }} </v-icon>
    </v-btn>
    <!-- Termina boton para exportar a excel -->

    <!-- Empieza boton para exporta a pdf -->
    <v-btn small class="ma-2" elevation="2" color="primary" @click="generatePDF">
      {{ pdfButtonText }}
      <span aria-hidden="true">
        <v-icon right>{{ pdfIcon }}</v-icon>
      </span>
    </v-btn>
    <!-- Termina boton para exportar a pdf -->
  </v-container>
</template>

<script>
// Se importan las dependencias
import JsonExcel from 'vue-json-excel'
import jsPDF from 'jspdf'
import 'jspdf-autotable'

export default {
  name: 'DataExporter',

  // Componentes de las dependencias
  components: {
    JsonExcel,
  },

  // Propiedades del componente
  props: {
    dataArray: {
      type: Array,
      default: null,
    },

    dataFields: {
      type: Object,
      default: null,
    },

    fileName: {
      type: String,
      default: 'data',
    },

    formatRules: {
      type: Object,
      default: () => ({}),
    },

    footer: {
      type: String,
      default: 'Shuttle Express México',
    },

    excelIcon: {
      type: String,
      default: 'mdi-file-excel-box',
    },

    pdfIcon: {
      type: String,
      default: 'mdi-file-pdf-box',
    },

    excelButtonText: {
      type: String,
      default: 'Descargar Excel',
    },

    pdfButtonText: {
      type: String,
      default: 'Descargar PDF',
    },
    subTitle: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: null,
    },
  },

  methods: {
    /**
     * Genera y descarga un PDF con los datos proporcionados.
     */
    generatePDF() {
      const doc = new jsPDF('landscape')
      const columnStyles = {}
      const tituloY = 10
      const subtituloY = 15
      const espacioExtra = 5
      const startY = subtituloY + espacioExtra
      // Configuración de estilos para el encabezado y las celdas
      const styles = {
        // Estilos para las celdas del encabezado
        headStyles: {
          fillColor: false, // Color de fondo del encabezado (azul)
          textColor: 0, // Color del texto (blanco)
          fontSize: 8, // Tamaño de la fuente
          fontStyle: 'bold', // Texto en negrita
          lineWidth: 0.1, // Grosor de la línea
        },
        // Estilos para las celdas del cuerpo
        bodyStyles: {
          fillColor: [220, 220, 220], // Color de fondo para las filas (gris claro)
          textColor: 0, // Color del texto (negro)
          fontSize: 6, // Tamaño de la fuente
          fontStyle: 'light',
        },
        // Alternar el color de fondo para las filas
        alternateRowStyles: {
          fillColor: 255, // Color de fondo para las filas alternas (blanco)
        },
      }

      Object.keys(this.dataFields).forEach((title, index) => {
        // Usando el título de la columna para encontrar la regla de formato
        const rule = this.formatRules[title]

        // Aplicar alineación solo si se especifica en formatRules
        if (rule && rule.align) {
          columnStyles[index] = { halign: rule.align }
        }
      })

      const body = this.getDataArrayForPDF()

      if (body.length) {
        doc.autoTable({
          startY: startY,
          head: [Object.keys(this.dataFields)],
          body,
          styles: styles.headStyles,
          bodyStyles: styles.bodyStyles,
          columnStyles: columnStyles,
          alternateRowStyles: styles.alternateRowStyles,
          // Nuevo
          didDrawPage: () => {
            // Añadir título y subtítulo
            doc.setFontSize(12)
            doc.text(this.title ? this.title : this.fileName, 14, tituloY)
            doc.setFontSize(9)
            doc.text(this.subTitle, 14, subtituloY)
          },
          // Termina nuevo
          didParseCell: (data) => {
            if (data.row.section === 'body') {
              const fieldKey = Object.keys(this.dataFields)[data.column.index]

              const rule = this.formatRules[fieldKey]
              if (rule && typeof rule.format === 'function') {
                data.cell.text = rule.format(data.cell.raw)
              }
            }
          },
        })

        // Añadir pie de página y numeración después de crear la tabla
        const pageCount = doc.internal.getNumberOfPages()

        for (let i = 1; i <= pageCount; i++) {
          doc.setPage(i)
          doc.setFontSize(9)
          doc.text(14, doc.internal.pageSize.height - 10, this.footer)
          doc.text(
            `${i} de ${pageCount}`,
            doc.internal.pageSize.width - 30,
            doc.internal.pageSize.height - 10
          )
        }
        doc.save(this.fileName + '.pdf')
      }
    },

    /**
     * Obtiene el valor de una propiedad de un objeto dada una ruta de acceso.
     * @param {Object} object - El objeto del cual obtener la propiedad.
     * @param {Array<String>} path - Ruta de acceso a la propiedad en el objeto.
     * @return {any} El valor de la propiedad si existe, de lo contrario null.
     */
    getPropertyValue(object, path) {
      return path.reduce((acc, key) => (acc && acc[key] ? acc[key] : null), object)
    },

    /**
     * Transforma el array de datos en un formato adecuado para el PDF.
     * @return {Array<Array<any>>} Un array de arrays con los datos para cada fila del PDF.
     */
    getDataArrayForPDF() {
      const paths = Object.values(this.dataFields).map((field) => field.split('.'))
      return this.dataArray.map((item) => paths.map((path) => this.getPropertyValue(item, path)))
    },
  },
}
</script>
