<template>
  <v-container id="paises" tag="section">
    <base-material-card
      class="px-5 py-3"
      color="primary"
      icon="mdi-flag-variant"
      inline
      :title="$t('countries')"
    >
      <v-spacer></v-spacer>

      <template v-slot:after-heading></template>

      <!-- Boton nuevo registro -->
      <template v-slot:corner-button>
        <v-btn class="ma-2" color="primary" elevation="2" small @click.stop="dialog = true"
          >Nuevo País</v-btn
        >
      </template>

      <!-- Overlay para retroalimentar al usuario -->
      <v-overlay :value="overlay">
        <v-progress-circular color="success" indeterminate :size="50"></v-progress-circular>
      </v-overlay>

      <!-- Texfield para la barra de búsqueda -->
      <v-text-field
        append-icon="mdi-magnify"
        class="ml-auto"
        hide-details
        label="Búsqueda"
        single-line
        style="max-width: 250px"
        v-model="search"
      />
      <!-- Dialogo Cancelar -->
      <ConfirmationDialog
        buttonColor1="error"
        buttonText1="NO"
        buttonColor2="primary"
        buttonText2="SI"
        text="Seguro quiere cancelar?"
        title="¡Se perderan todos los cambios!"
        :width="420"
        v-model="isSure"
        @click="cancel"
      />

      <!-- Dialogo Agregar/ Editar Paises-->
      <v-dialog v-model="dialog" max-width="500px" persistent>
        <v-card>
          <!--           <v-card-title>
            <span class="headline">{{ formTitle }}</span>
          </v-card-title> -->

          <v-card-text>
            <base-subheading :subheading="formTitle" />
            <v-container>
              <v-row>
                <!-- Pais -->
                <v-col cols="12">
                  <v-text-field
                    counter="20"
                    label="Pais*"
                    maxlength="20"
                    required
                    v-model="$v.editedItem.pais.$model"
                    :error-messages="paisErrors"
                    @blur="$v.editedItem.pais.$touch()"
                    @input="$v.editedItem.pais.$reset(), (changed = true)"
                    @keypress="isLetter($event)"
                  ></v-text-field>
                </v-col>

                <!-- Siglas -->
                <v-col cols="12">
                  <v-text-field
                    counter="3"
                    label="Siglas*"
                    maxlength="3"
                    required
                    v-model="$v.editedItem.pais_corto.$model"
                    :error-messages="pais_cortoErrors"
                    @blur="$v.editedItem.pais_corto.$touch()"
                    @input="
                      $v.editedItem.pais_corto.$reset(),
                        (editedItem.pais_corto = editedItem.pais_corto.toUpperCase())
                      changed = true
                    "
                    @keypress="isLetter($event)"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
            <small>*Campo obligatorio</small>
          </v-card-text>

          <!-- Botones y CheckList -->
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-checkbox
              label="Crear otro"
              v-if="editedIndex === -1"
              v-model="checkbox"
            ></v-checkbox>

            <!-- Botones de guardar y cancelar -->
            <v-btn color="error" text @click="checkChanges">Cancelar</v-btn>
            <v-btn color="primary" text :disabled="$v.$invalid" @click="save">Guardar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-divider class="mt-3" />

      <!-- Data Table de Paises  -->
      <v-data-table
        dense
        loading-text="Cargando... Espere por favor"
        :headers="headers"
        :items="paises"
        :loading="isLoading"
        :search.sync="search"
      >
        <!-- Slot del boton Activo-->
        <template v-slot:[`item.activo`]="{ item }">
          <Status :activo="item.activo" />
        </template>

        <!-- Slot de Acciones -->
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon class="mr-2" small @click="editItem(item)">mdi-pencil</v-icon>
          <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
        </template>

        <template v-slot:no-data>
          <!-- <v-btn color="primary">Reset</v-btn> -->
          ¡No hay datos para mostrar!</template
        >
      </v-data-table>

      <!-- DataExporter -->
      <DataExporter :dataArray="paises" :dataFields="fields" fileName="Paises" />
    </base-material-card>
  </v-container>
</template>

<script>
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'

import ConfirmationDialog from '@/components/general/ConfirmationDialog.vue'
import DataExporter from '@/components/general/DataExporter.vue'
import Status from '@/components/general/StatusInfo.vue'

export default {
  components: {
    ConfirmationDialog,
    DataExporter,
    Status,
  },
  data: () => ({
    changed: false,
    checkbox: false,
    dialog: false,
    editedIndex: -1,
    isEditing: false,
    isLoading: false,
    isSure: false,
    overlay: false,
    search: undefined,
    headers: [
      { text: 'País', align: 'start', sortable: false, value: 'pais' },
      { text: 'Código', value: 'pais_corto' },
      { text: 'Activo', value: 'activo' },
      { text: 'Acciones', value: 'actions', sortable: false },
    ],
    paises: [],
    value: [],
    defaultItem: {
      _id: '',
      pais: '',
      pais_corto: '',
    },
    editedItem: {
      _id: '',
      pais: '',
      pais_corto: '',
    },
    fields: {
      Pais: 'pais',
      Codigo: 'pais_corto',
    },
  }),

  mixins: [validationMixin],

  //Validaciones para los campos del formulario
  validations: {
    editedItem: {
      pais: { required, minLength: minLength(3), maxLength: maxLength(20) },
      pais_corto: { required, minLength: minLength(3), maxLength: maxLength(3) },
    },
  },

  computed: {
    //Titulo de los formularios
    formTitle() {
      return this.editedIndex === -1 ? 'Nuevo País' : 'Editar País'
    },

    // Propiedad computada para errores en campo Siglas
    pais_cortoErrors() {
      const errors = []
      if (!this.$v.editedItem.pais_corto.$dirty) return errors
      !this.$v.editedItem.pais_corto.required && errors.push('Este campo es requerido')
      !this.$v.editedItem.pais_corto.minLength &&
        errors.push('Este campo debe de tener un mínimo de 3 caracteres.')
      !this.$v.editedItem.pais_corto.maxLength &&
        errors.push('Este campo debe de tener un máximo de 3 caracteres.')
      return errors
    },

    // Propiedad computada para errores en campo Pais
    paisErrors() {
      const errors = []
      if (!this.$v.editedItem.pais.$dirty) return errors
      !this.$v.editedItem.pais.required && errors.push('Este campo es requerido')
      !this.$v.editedItem.pais.minLength &&
        errors.push('Este campo debe de tener un mínimo de 3 caracteres.')
      !this.$v.editedItem.pais.maxLength &&
        errors.push('Este campo debe de tener un máximo de 20 caracteres.')
      return errors
    },
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
  },

  created() {
    this.obtenerPaises()
  },

  methods: {
    /**
     * @description Elimina un registro de la base de datos y del data table
     */
    async BorrarRegistro() {
      this.overlay = true
      this.$v.$touch()

      let body = {
        _id: this.editedItem._id,
      }
      let payload = { body: body }
      payload.id = this.editedItem._id
      this.$store
        .dispatch('country/deleteCountry', payload)
        .then(() => {
          this.$store.commit('ALERT', {
            color: 'success',
            text: 'El registro se elimino con éxito',
          })
          this.overlay = false
        })
        .catch((err) => {
          this.$store.commit('ALERT', {
            color: 'error',
            text: err.response.data.msg,
          })
          this.overlay = false
        })
      this.editedItem = Object.assign({}, this.defaultItem)
    },

    /**
     * @description confirma la cancelacion de la edicion en el formulario
     * de mantenimiento de zonas
     */
    cancel(isSure) {
      this.isSure = isSure
      if (this.isSure) {
        this.close(false)
        this.changed = false
      }
    },

    /**
     * @description Revisa si hay cambios en el formulario de mantenimiento de
     * paises
     */
    checkChanges() {
      if (this.changed) {
        this.isSure = true
      } else {
        this.close(false)
        this.changed = false
      }
    },

    /**
     * @description Cierra el diálogo de mantenimiento de paises
     */
    close(checkbox) {
      this.changed = false
      this.isSure = false
      this.checkbox = checkbox
      this.isEditing = false
      if (!checkbox) {
        this.dialog = false
      }
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
      this.$v.$reset()
    },

    /**
     * @description elimina un registro de la tabla y de la base de datos
     */
    deleteItem(item) {
      const index = this.paises.indexOf(item)
      this.editedItem = Object.assign({}, item)
      if (confirm('Esta seguro que desea eliminar este registro?')) {
        this.BorrarRegistro()
        this.paises.splice(index, 1)
      }
    },

    /**
     * @description prepara un registro para su edición y muestra el dialogo
     * para editar paises
     */
    editItem(item) {
      this.isEditing = true
      this.editedIndex = this.paises.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    /**
     * @description Evalua el caracter para saber si es una letra
     */
    isLetter: function (e) {
      let char = String.fromCharCode(e.keyCode)
      if (/^[a-zA-Z\u00C0-\u00FF ]*$/.test(char)) return true
      else e.preventDefault()
    },

    /**
     * @description Obtiene un listado de todas las paises
     */
    async obtenerPaises() {
      this.isLoading = true
      let payload = {}
      this.$store
        .dispatch('country/fetchCountries', payload)
        .then((response) => {
          this.paises = response.data
          this.isLoading = false
        })
        .catch((err) => {
          this.isLoading = false
          this.$store.commit('ALERT', {
            color: 'error',
            text: err.response.data.error,
          })
        })
    },

    /**
     * @description Guarda el contenido del formulario ya sea para un país nuevo o
     * la edicion de un país
     */
    async save() {
      this.overlay = true
      this.$v.$touch()
      if (!this.$v.$invalid) {
        let body = {
          pais: this.editedItem.pais,
          pais_corto: this.editedItem.pais_corto,
        }
        let payload = { body: body }
        if (this.editedIndex > -1) {
          payload.id = this.editedItem.id
          this.$store
            .dispatch('country/editCountry', payload)
            .then(() => {
              this.$store.commit('ALERT', {
                color: 'success',
                text: 'El registro se actualizo con éxito',
              })
              this.overlay = false
            })
            .catch((err) => {
              this.$store.commit('ALERT', {
                color: 'error',
                text: err.response.data.msg,
              })
              this.overlay = false
            })
          Object.assign(this.paises[this.editedIndex], this.editedItem)
        } else {
          this.$store
            .dispatch('country/addCountry', payload)
            .then((response) => {
              this.$store.commit('ALERT', {
                color: 'success',
                text: 'El registro se creo con éxito',
              })
              this.paises.push(response.data)
              this.overlay = false
            })
            .catch((err) => {
              this.$store.commit('ALERT', {
                color: 'error',
                text: err.response.data.msg,
              })
              this.overlay = false
            })
        }
        this.close(this.checkbox)
        this.changed = false
      }
    },
  },
}
</script>
