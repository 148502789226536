<template>
  <!-- <v-chip-group column active-class="primary--text"> -->
  <v-chip
    class="ma-1"
    label
    x-small
    :color="chipColor"
    text-color="white"
    @click.stop="$emit('statusChanged')"
  >
    {{ chipText }}
  </v-chip>
  <!-- </v-chip-group> -->
</template>

<script>
export default {
  name: 'Status',
  props: {
    activo: {
      type: Boolean,
      default: true,
    },
    txtTrue: {
      type: String,
      default: 'Activo',
    },
    txtFalse: {
      type: String,
      default: 'Inactivo',
    },
    colTrue: {
      type: String,
      default: 'success',
    },
    colFalse: {
      type: String,
      default: 'error',
    },
  },
  computed: {
    chipColor() {
      return this.activo ? this.colTrue : this.colFalse
    },
    chipText() {
      return this.activo ? this.txtTrue : this.txtFalse
    },
  },
}
</script>
